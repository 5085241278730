import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { useSettingsContext } from 'src/components/settings';

export default function SettingsButton({ sx }) {
  const settings = useSettingsContext();
  const [selectedBranch, setSelectedBranch] = useState('');

  const handleChange = (event) => {
    setSelectedBranch(event.target.value);
    // Add additional logic if needed
  };

  return (
    <Select
      value={selectedBranch}
      onChange={handleChange}
      sx={{ width: '200px', ...sx }}
      displayEmpty
    >
      <MenuItem value="" disabled>
        Studio Branch 1
      </MenuItem>
      {/* <MenuItem value={'option1'}>Branch 1</MenuItem>
      <MenuItem value={'option2'}>Branch 2</MenuItem>
      <MenuItem value={'option3'}>Branch 3</MenuItem> */}
    </Select>
  );
}

SettingsButton.propTypes = {
  sx: PropTypes.object,
};
