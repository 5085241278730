// routes
import { paths } from "src/routes/paths";
// config
import { PATH_AFTER_LOGIN } from "src/config-global";
// components
import Iconify from "src/components/iconify";

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: "Home",
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: "/",
  },
  {
    title: "About Us",
    icon: <Iconify icon="solar:document-bold-duotone" />,
    path: "",
  },

  {
    title: "Subscriptions",
    icon: <Iconify icon="solar:file-favourite-bold-duotone" />,
    path: "",
  },

  {
    title: "Contact Us",
    icon: <Iconify icon="solar:call-chat-rounded-bold-duotone" />,
    path: "",
  },
];
