import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  dashboard: icon('ic_dashboard'),
  notification: icon('ic_notification'),
  tour: icon('ic_tour'),
  ecommerce: icon('ic_ecommerce'),
};

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        items: [
          // DASHBOARD
          {
            title: t('Dashboard'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('Bookings'),
            path: paths.dashboard.booking.root,
            icon: ICONS.booking,
            // children: [
            //   { title: t('list'), path: paths.dashboard.customer.list },
            //   { title: t('profiltotae'), path: paths.dashboard.customer.profile },
            //   { title: t('create'), path: paths.dashboard.customer.new },
            //   { title: t('edit'), path: paths.dashboard.customer.demo.edit },
            //   { title: t('account'), path: paths.dashboard.customer.account },
            // ],
          },
          // BOOKING
          // {
          //   title: t('booking'),
          //   path: paths.dashboard.general.booking,
          //   icon: ICONS.booking,
          // },
          // CALENDAR
          {
            title: t('calendar'),
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
          },
          // CUSTOMERS
          {
            title: t('Customers'),
            path: paths.dashboard.customer.root,
            icon: ICONS.user,
            // children: [
            //   { title: t('list'), path: paths.dashboard.customer.list },
            //   { title: t('profiltotae'), path: paths.dashboard.customer.profile },
            //   { title: t('create'), path: paths.dashboard.customer.new },
            //   { title: t('edit'), path: paths.dashboard.customer.demo.edit },
            //   { title: t('account'), path: paths.dashboard.customer.account },
            // ],
          },
          // FLEXOLOGIST
          {
            title: t('Flexologists'),
            path: paths.dashboard.flexologist.root,
            icon: ICONS.ecommerce,
            // children: [
            //   { title: t('Availability'), path: paths.dashboard.flexologist.availability },
            //   // { title: t('profile'), path: paths.dashboard.customer.profile },
            //   // { title: t('create'), path: paths.dashboard.customer.new },
            //   // { title: t('edit'), path: paths.dashboard.customer.demo.edit },
            //   // { title: t('account'), path: paths.dashboard.customer.account },
            // ],
          },
          {
            title: t('Subscriptions'),
            path: paths.dashboard.subscription.root,
            icon: ICONS.order,
            // children: [
            //   { title: t('list'), path: paths.dashboard.customer.list },
            //   { title: t('profile'), path: paths.dashboard.customer.profile },
            //   { title: t('create'), path: paths.dashboard.customer.new },
            //   { title: t('edit'), path: paths.dashboard.customer.demo.edit },
            //   { title: t('account'), path: paths.dashboard.customer.account },
            // ],
          },
          // PAYMENT INVOICE
          {
            title: t('Payments'),
            path: paths.dashboard.payment.root,
            icon: ICONS.invoice,
            // children: [
            //   { title: t('list'), path: paths.dashboard.invoice.root },
            //   { title: t('create'), path: paths.dashboard.invoice.new },
            //   { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
            // ],
          },
          // APPOINTMENTS
          // {
          //   title: t('appointments'),
          //   path: paths.dashboard.appointments.root,
          //   icon: ICONS.tour,
          //   // children: [
          //   //   { title: t('list'), path: paths.dashboard.appointments.root },
          //   // ],
          // },
          // SUBSCRIPTION
          // {
          //   title: t('Subscription'),
          //   path: paths.dashboard.subscription.root,
          //   icon: ICONS.order,
          // },
          // FAQ
          {
            title: t('FAQs'),
            path: paths.dashboard.adminFaqs,
            icon: ICONS.blog,
          },
          // TERMS & CONDITION
          {
            title: t('Terms & Condition'),
            path: paths.dashboard.terms,
            icon: ICONS.blank,
          },
          // PRIVACY & POLICY
          {
            title: t('Privacy & Policy'),
            path: paths.dashboard.privacy,
            icon: ICONS.kanban,
          },
          // CONTACT SUPPORT
          {
            title: t('Contact Support'),
            path: paths.dashboard.support,
            icon: ICONS.chat,
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
