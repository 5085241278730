// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  homeTerms: '/homeTerms',
  homePrivacy: '/homePrivacy',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  docs: 'https://docs.FlexLabs.cc',
  changelog: 'https://docs.FlexLabs.cc/changelog',
  zoneUI: 'https://mui.com/store/items/zone-landing-page/',
  FlexLabsUI: 'https://mui.com/store/items/FlexLabs-dashboard/',
  freeUI: 'https://mui.com/store/items/FlexLabs-dashboard-free/',
  figma:
    'https://www.figma.com/file/hjxMnGUJCjY7pX8lQbS7kn/%5BPreview%5D-FlexLabs-Web.v5.4.0?type=design&node-id=0-1&mode=design&t=2fxnS70DuiTLGzND-0',
  product: {
    root: `/product`,
    checkout: `/product/checkout`,
    details: (id) => `/product/${id}`,
    demo: {
      details: `/product/${MOCK_ID}`,
    },
  },
  post: {
    root: `/post`,
    details: (title) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },
  
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    support: `${ROOTS.DASHBOARD}/support`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    adminFaqs: `${ROOTS.DASHBOARD}/adminFaqs`,
    terms: `${ROOTS.DASHBOARD}/terms`,
    privacy: `${ROOTS.DASHBOARD}/privacy`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      // booking: `${ROOTS.DASHBOARD}/booking`,
    },
    booking: {
      root: `${ROOTS.DASHBOARD}/booking/list`,
      new: `${ROOTS.DASHBOARD}/booking/new`,
      list: `${ROOTS.DASHBOARD}/booking/list`,
      cards: `${ROOTS.DASHBOARD}/booking/cards`,
      profile: `${ROOTS.DASHBOARD}/booking/profile`,
      account: `${ROOTS.DASHBOARD}/booking/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    customer: {
      root: `${ROOTS.DASHBOARD}/customer/list`,
      new: `${ROOTS.DASHBOARD}/customer/new`,
      list: `${ROOTS.DASHBOARD}/customer/list`,
      cards: `${ROOTS.DASHBOARD}/customer/cards`,
      profile: `${ROOTS.DASHBOARD}/customer/profile`,
      account: `${ROOTS.DASHBOARD}/customer/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    flexologist: {
      root: `${ROOTS.DASHBOARD}/flexologist/list`,
      new: `${ROOTS.DASHBOARD}/flexologist/new`,
      list: `${ROOTS.DASHBOARD}/flexologist/list`,
      cards: `${ROOTS.DASHBOARD}/flexologist/cards`,
      profile: `${ROOTS.DASHBOARD}/flexologist/profile`,
      account: `${ROOTS.DASHBOARD}/flexologist/account`,
      availability: `${ROOTS.DASHBOARD}/flexologist/availability`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    subscription: {
      root: `${ROOTS.DASHBOARD}/subscription/list`,
      new: `${ROOTS.DASHBOARD}/subscription/new`,
      list: `${ROOTS.DASHBOARD}/subscription/list`,
      cards: `${ROOTS.DASHBOARD}/subscription/cards`,
      profile: `${ROOTS.DASHBOARD}/subscription/profile`,
      account: `${ROOTS.DASHBOARD}/subscription/account`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    payment: {
      root: `${ROOTS.DASHBOARD}/payment`,
      new: `${ROOTS.DASHBOARD}/payment/new`,
      details: (id) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    appointments: {
      root: `${ROOTS.DASHBOARD}/appointments`,
      details: (id) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    // subscription: {
    //   root: `${ROOTS.DASHBOARD}/subscription`,
    //   new: `${ROOTS.DASHBOARD}/user/new`,
    //   details: (id) => `${ROOTS.DASHBOARD}/user/${id}`,
    //   edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    //   demo: {
    //     details: `${ROOTS.DASHBOARD}/user/${MOCK_ID}`,
    //     edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
    //   },
    // },
  },
};
