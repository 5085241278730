import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
// const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// Customer
// const CustomerProfilePage = lazy(() => import('src/pages/dashboard/customer/profile'));
// const CustomerCardsPage = lazy(() => import('src/pages/dashboard/customer/cards'));
// const CustomerListPage = lazy(() => import('src/pages/dashboard/customer/list'));
// const CustomerAccountPage = lazy(() => import('src/pages/dashboard/customer/account'));
// const CustomerCreatePage = lazy(() => import('src/pages/dashboard/customer/new'));
// const CustomerEditPage = lazy(() => import('src/pages/dashboard/customer/edit'));


export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      // { element: <IndexPage />, index: true },
      // {
      //   path: 'customer',
      //   children: [
      //     { element: <CustomerProfilePage />, index: true },
      //     { path: 'profile', element: <CustomerProfilePage /> },
      //     { path: 'cards', element: <CustomerCardsPage /> },
      //     { path: 'list', element: <CustomerListPage /> },
      //     { path: 'new', element: <CustomerCreatePage /> },
      //     { path: ':id/edit', element: <CustomerEditPage /> },
      //     { path: 'account', element: <CustomerAccountPage /> },
      //   ],
      // },
    ],
  },
];
