// @mui
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// routes
import { paths } from "src/routes/paths";
import { usePathname } from "src/routes/hooks";
import { RouterLink } from "src/routes/components";
// _mock
import { _socials } from "src/_mock";
// components
import Logo from "src/components/logo";
import Iconify from "src/components/iconify";
import { Button, InputAdornment, TextField } from "@mui/material";
import { EmailInboxIcon } from "src/assets/icons";
import { Link } from "react-router-dom";

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: "Facebook", href: "" },
  { name: "Apple", href: "" },
  { name: "Google", href: "" },
];

const LINKS = [
  {
    headline: "Banter",
    children: [
      // { name: 'About us', href: paths.about },
      { name: "About us", href: "" },
      { name: "Pricing Plan", href: "" },
      { name: "Download App", href: "" },
      { name: "Contact Us", href: "" },
    ],
  },
  {
    headline: "Legal",
    children: [
      // { name: 'FAQs', href: paths.faqs },
      { name: "Privacy Policy", href: "" },
      { name: "Terms & Condition", href: "" },
      { name: "Terms of Use", href: "" },
    ],
  },
  {
    headline: "Find Us",
    children: [
      { name: "Facebook", href: "#" },
      { name: "Apple", href: "#" },
      { name: "Google", href: "#" },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const pathname = usePathname();

  const isHome = pathname === "/";

  const mainFooter = (
    <Container
      sx={{
        textAlign: { xs: "center", md: "unset" },
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: [500], marginBottom: "2rem" }}>
        ©️ 2024 Conversational Intelligence {" "}
        <Link to="/privacy" style={{ textDecoration: "none" }}>
          <span
            style={{
              
              color: "#e91e63",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Privacy Policy
          </span>
        </Link>
      </Typography>
    </Container>
  );

  return mainFooter;
}
